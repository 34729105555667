import {getLSItem} from "v4/services/localStorage.service";
import getCleanViewOrdersGroups from "v4/pages/admin/ViewOrders/contexts/utils/getCleanViewOrdersGroups";
import {CONTACT, PRODUCT, PROSPECT, QUOTE, TASK} from "v4/data/entities";

export default function getCleanViewOrders() {
    const viewOrdersFromLocalStorage = getLSItem('user')?.orders?.['hydra:member'][0];
    if (!viewOrdersFromLocalStorage) return {};

    const viewOrders = Object.entries(viewOrdersFromLocalStorage).reduce((acc, [entity, viewOrder]) => {
        const {idViewOrderCustomer, form, search} = viewOrder;
        acc[entity] = {
            id: idViewOrderCustomer,
            ...(form && {form: getCleanViewOrdersGroups(entity, form)}),
            ...(search && {search: getCleanViewOrdersGroups(entity, search, true)}),
        };
        return acc;
    }, {});

    // Return sorted view orders by entity name and remove unhandled entities
    return Object.keys(viewOrders).sort().reduce((acc, entityName) => {
        if ([PROSPECT, CONTACT, TASK, QUOTE, PRODUCT].includes(entityName.toLowerCase())) {
            acc[entityName] = viewOrders[entityName];
        }
        return acc;
    }, {});
}
