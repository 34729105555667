import getRealFieldType from "v4/pages/admin/ViewOrders/contexts/utils/getRealFieldType";
import i18n from "i18next";

export default function appendNotExistingGroups(viewOrder, fields) {
    if (!viewOrder || !fields) return [];

    const notExistingFields = fields.filter(({key: fieldKey}) => {
        return !viewOrder.some(({fields: groupFields}) => {
            return groupFields.some(({key}) => key === fieldKey);
        });
    });

    const [requiredFields, defaultFields] = notExistingFields.reduce((acc, field) => {
        const {required: isRequired} = field;
        if (isRequired) {
            acc[0].push(newFieldFromOldField(field));
        } else {
            acc[1].push(newFieldFromOldField(field));
        }
        return acc;
    }, [[], []]);

    return [
        {
            isRequired: true,
            id: 'isRequired',
            label: i18n?.t('isRequired') ?? 'isRequired',
            width: 100,
            fields: [...requiredFields]
        },
        {
            isDefault: true,
            id: 'default',
            label: 'default',
            width: 100,
            fields: [...defaultFields]
        }
    ];
}

function newFieldFromOldField(oldField) {
    const {
        id,
        title: label,
        key,
        fixedKey,
        required: isRequired = false,
        attr: {
            'data-type': attrDataType,
            type: attrType,
            email: isEmail = false,
            isExtended = false,
            readonly: isReadOnly = false,
        } = {},

    } = oldField;

    const type = getRealFieldType(oldField);

    return {
        id: id ?? key,
        label,
        ...(fixedKey.startsWith('deliveryAddress_') && {label: `${i18n?.t(label)} (${i18n?.t('delivery')})`}),
        key,
        width: 100,
        type,
        isUnique: false,
        isRequired,
        isReadOnly,
        isSpecificField: attrDataType === 'specific-fields',
        isHidden: attrType === 'hidden',
        isMonetary: false,
        isPercentage: false,
        isPhone: false,
        isUrl: false,
        isRichText: false,
        isEmail,
        isParentField: false,
        isExtended,
    }
}
