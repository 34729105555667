import React from 'react';
import {PolIcon} from "components/PolIcon/policon";
import useSetDOMInfos from "v4/pages/admin/ViewOrders/hooks/useSetDOMInfos";
import ViewOrdersWidthButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersWidthButton/ViewOrdersWidthButton";
import ViewOrdersConfigButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersConfigButton/ViewOrdersConfigButton";
import ViewOrdersRemoveButton
    from "v4/pages/admin/ViewOrders/components/ViewOrdersButtons/ViewOrdersRemoveButton/ViewOrdersRemoveButton";
import {usePolTranslation} from "v4/hooks/usePolTranslation";

export default function ViewOrdersField({group, field, hasActions = true}) {
    const {t} = usePolTranslation();
    const fieldRef = useSetDOMInfos(field);

    const getFieldLabel = (field) => {
        let finalLabel = t(field.label);

        if (field.isRequired) {
            finalLabel = field.isRequiredNotRemovable ? <b>{finalLabel}</b> : `${finalLabel}*`;
        }

        if (field.isSpecificField) {
            finalLabel = <span className="new-view-orders__field__label__specific">{finalLabel}</span>
        }

        return finalLabel;
    }

    const getTypeIcons = (field) => {
        const icons = [];

        if (field.type === 'ChoiceType') icons.push(<PolIcon key="text" icon="list-check"/>);

        if (field.isUnique) icons.push(<PolIcon key="unique" icon="unique"/>);
        if (field.isReadOnly) icons.push(<PolIcon key="readOnly" icon="eye-cancel"/>);
        if (field.isEmail) icons.push(<PolIcon key="email" icon="mail"/>);
        if (field.isPhone) icons.push(<PolIcon key="phone" icon="phone-alt"/>);
        if (field.isMonetary) icons.push(<PolIcon key="monetary" icon="euro"/>);
        if (field.isPercentage) icons.push(<PolIcon key="percentage" icon="percent"/>);
        if (field.isRichText) icons.push(<PolIcon key="richText" icon="paragraph"/>);
        if (field.isUrl) icons.push(<PolIcon key="url" icon="web"/>);
        if (field.isExtended) icons.push(<PolIcon key="extended" icon="tab"/>);
        if (field.isParentField) icons.push(<PolIcon key="parentField" icon="p-square"/>);

        return icons;
    }

    return (
        <div className={`new-view-orders__field ${getWidthClasseForField(group.width, field.width)}`}
             ref={fieldRef}>
            <div className="new-view-orders__field__label">
                <PolIcon icon="draggable" className="new-view-orders__field__icon"/>
                <p className="new-view-orders__field__label-icons">
                    {getTypeIcons(field)}
                    {getFieldLabel(field)}
                </p>
            </div>
            {hasActions && (
                <div className="new-view-orders__field__actions">
                    <ViewOrdersWidthButton group={group} field={field}/>
                    <ViewOrdersConfigButton group={group} field={field}/>
                    {!group.isRequired && <ViewOrdersRemoveButton group={group} field={field}/>}
                </div>
            )}
        </div>
    )
}

/*
G: 25 = 1/4 = vo-col-3
    F: 100 = 1 = vo-col-12
G: 33 = 1/3 = vo-col-4
    F: 100 = 1 = vo-col-12
G: 50 = 1/2 = vo-col-6
    F: 50 = 1/2 = vo-col-6
    F: 100 = 1 = vo-col-12
G: 66 = 2/3 = vo-col-8
    F: 50 = 1/2 = vo-col-6
    F: 100 = 1 = vo-col-12
G: 75 = 3/4 = vo-col-9
    F: 33 = 1/3 = vo-col-4
    F: 50 = 1/2 = vo-col-6
    F: 66 = 2/3 = vo-col-8
    F: 100 = 1 = vo-col-12
G: 100 = 1 = vo-col-12
    F: 25 = 1/4 = vo-col-3
    F: 33 = 1/3 = vo-col-4
    F: 50 = 1/2 = vo-col-6
    F: 66 = 2/3 = vo-col-8
    F: 75 = 3/4 = vo-col-9
    F: 100 = 1 = vo-col-12
*/
function getWidthClasseForField(groupWidth, fieldWidth) {

    switch (+groupWidth) {
        case 25:
        case 33:
            return 'vo-col-12';
        case 50:
            switch (fieldWidth) {
                case 50:
                    return 'vo-col-6';
                default:
                    return 'vo-col-12';
            }
        case 66:
            switch (fieldWidth) {
                case 50:
                    return 'vo-col-6';
                default:
                    return 'vo-col-12';
            }
        case 75:
            switch (fieldWidth) {
                case 33:
                    return 'vo-col-4';
                case 50:
                    return 'vo-col-6';
                case 66:
                    return 'vo-col-8';
                default:
                    return 'vo-col-12';
            }
        case 100:
            switch (fieldWidth) {
                case 25:
                    return 'vo-col-3';
                case 33:
                    return 'vo-col-4';
                case 50:
                    return 'vo-col-6';
                case 66:
                    return 'vo-col-8';
                case 75:
                    return 'vo-col-9';
                default:
                    return 'vo-col-12';
            }
        default:
            return 'vo-col-12';
    }
}
